import {
  STUDENT_LOGIN_REQUEST,
  STUDENT_LOGIN_SUCCESS,
  STUDENT_LOGIN_FAILURE,
  ADVISOR_LOGIN_REQUEST,
  ADVISOR_LOGIN_SUCCESS,
  ADVISOR_LOGIN_FAILURE,
  RELOAD_USER_DETAILS_REQUEST,
  RELOAD_USER_DETAILS_SUCCESS,
  RELOAD_USER_DETAILS_FAILURE,
  LOAD_USER_DETAILS_REQUEST,
  LOAD_USER_DETAILS_SUCCESS,
  LOAD_USER_DETAILS_FAILURE,
  LOGOUT,
} from "../constants/authConstants";

import { reactLocalStorage } from "reactjs-localstorage";
import {
  token,
  email,
  clientID,
  refreshToken,
  discussionBoardIDLS,
  citizenshipIDLS,
  isAdmin,
} from "../config/localStorage";

const initialState = {
  token: reactLocalStorage.get(token, null, true),
  isAuthenticated: null,
  loading: false,
  email: reactLocalStorage.get(email, null, true),
  fullName: null,
  clientID: reactLocalStorage.get(clientID, null, true),
  refreshToken: reactLocalStorage.get(refreshToken, null, true),
  userRole: null,
  userID: null,
  discussionBoardID: reactLocalStorage.get(discussionBoardIDLS, null, true),
  isParent: null,
  isAdmin: reactLocalStorage.get(isAdmin, null, true),
};

export const authReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case STUDENT_LOGIN_REQUEST:
    case ADVISOR_LOGIN_REQUEST:
    case RELOAD_USER_DETAILS_REQUEST:
    case LOAD_USER_DETAILS_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case STUDENT_LOGIN_SUCCESS:
      reactLocalStorage.set(token, payload.token);
      reactLocalStorage.set(email, payload.email);
      reactLocalStorage.set(discussionBoardIDLS, payload.discussionBoardID);
      reactLocalStorage.set(citizenshipIDLS, payload.citizenship);
      return {
        ...state,
        token: payload.token,
        isAuthenticated: payload.isAuthenticated,
        loading: false,
        email: payload.email,
        discussionBoardID: payload.discussionBoardID,
        citizenship: payload.citizenship,
      };

    case ADVISOR_LOGIN_SUCCESS:
      reactLocalStorage.set(token, payload.token);
      reactLocalStorage.set(email, payload.email);
      reactLocalStorage.set(clientID, payload.clientID);
      reactLocalStorage.set(refreshToken, payload.refreshToken);
      reactLocalStorage.set(discussionBoardIDLS, payload.discussionBoardID);
      payload.isAdmin && reactLocalStorage.set(isAdmin, payload.isAdmin);
      return {
        ...state,
        token: payload.token,
        isAuthenticated: payload.isAuthenticated,
        loading: false,
        email: payload.email,
        clientID: payload.clientID,
        refreshToken: payload.refreshToken,
        discussionBoardID: payload.discussionBoardID,
        isAdmin: payload.isAdmin,
      };

    case RELOAD_USER_DETAILS_SUCCESS:
    case LOAD_USER_DETAILS_SUCCESS:
      reactLocalStorage.set(token, payload.token);
      reactLocalStorage.set(email, payload.email);
      reactLocalStorage.set(discussionBoardIDLS, payload.discussionBoardID);
      return {
        ...state,
        token: payload.token,
        isAuthenticated: payload.isAuthenticated,
        loading: false,
        email: payload.email,
        fullName: payload.fullName,
        userRole: payload.userRole,
        userID: payload.userID,
        isParent: payload.isParent,
        discussionBoardID: payload.discussionBoardID,
        citizenship: payload.citizenship,
      };

    case RELOAD_USER_DETAILS_FAILURE:
    case LOGOUT:
      reactLocalStorage.remove(token);
      reactLocalStorage.remove(email);
      reactLocalStorage.remove(clientID);
      reactLocalStorage.remove(refreshToken);
      reactLocalStorage.remove(discussionBoardIDLS);
      reactLocalStorage.remove(isAdmin);
      return {
        ...state,
        token: null,
        isAuthenticated: false,
        // loading: true,
        loading: false,
        email: null,
        fullName: null,
        clientID: null,
        refreshToken: null,
        userRole: null,
        userID: null,
        discussionBoardID: null,
        isParent: null,
      };
    case ADVISOR_LOGIN_FAILURE:
    case LOAD_USER_DETAILS_FAILURE:
    case STUDENT_LOGIN_FAILURE:
      reactLocalStorage.remove(token);
      reactLocalStorage.remove(email);
      reactLocalStorage.remove(clientID);
      reactLocalStorage.remove(refreshToken);
      reactLocalStorage.remove(discussionBoardIDLS);
      return {
        ...state,
        token: null,
        isAuthenticated: false,
        loading: true,
        email: null,
        fullName: null,
        clientID: null,
        refreshToken: null,
        userRole: null,
        userID: null,
        discussionBoardID: null,
        isParent: null,
      };
    default:
      return state;
  }
};
