import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import "./alert.css";
import { REMOVE_ALERT } from "../../constants/alertConstants";
import { removeAlert } from "../../actions/alertActions";

const AlertDialogBox = () => {
  const dispatch = useDispatch();
  const alerts = useSelector((state) => state.alert);
  // const alerts = [
  //   { msg: "testing message, please ignore", alertType: "primary", id: 1 },
  // ];

  const [showAlert, setShowAlert] = useState(false);
  const handleCloseWarning = () => {
    setShowAlert(false);
  };

  const handleClose = (id) => {
    dispatch(removeAlert(id));
  };

  useEffect(() => {
    if (alerts.length > 0) {
      setShowAlert(true);
    }
  }, [alerts]);

  return (
    <div className="custom-alert">
      {alerts.map((alert) => (
        // <Modal
        // 	key={alert.id}
        // 	show={showAlert}
        // 	onHide={handleCloseWarning}
        // 	className='warning-modal'
        // >
        // 	<Modal.Header closeButton></Modal.Header>
        // 	<Modal.Body>
        // 		<h6
        // 			className='mb-0 pb-5 black-color warning-msg-title text-center'
        // 			style={{textTransform: 'capitalize'}}
        // 		>
        // 			{alert.msg}
        // 		</h6>
        // 	</Modal.Body>
        // </Modal>
        <div
          class={`alert alert-demo alert-${alert.alertType} alert-dismissible fade show`}
          role="alert"
        >
          <strong>
            {alert.msg || "Something went wrong, please try again!"}
          </strong>
          <button
            type="button"
            // class="close"
            data-dismiss="alert"
            onClick={() => handleClose(alert.id)}
            // aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      ))}
    </div>
  );
};

export default AlertDialogBox;
