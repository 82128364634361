import { reactLocalStorage } from "reactjs-localstorage";
import {
  studentEmailLS,
  studentNameLS,
  studentUserIDLS,
  studentDBIDLS,
  studentCitizenshipIDLS,
  tutorEmailLS,
  tutorNameLS,
  tutorUserIDLS,
} from "../config/localStorage";
import {
  LOAD_TUTOR_DETAILS,
  REMOVE_TUTOR_DETAILS,
} from "../constants/tutorInfoConstants";

const initialState = {
  tutorEmail: reactLocalStorage.get(tutorEmailLS, null, true),
  tutorName: reactLocalStorage.get(tutorNameLS, null, true),
  tutorUserID: reactLocalStorage.get(tutorUserIDLS, null, true),
};

export const tutorInfoReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case LOAD_TUTOR_DETAILS:
      reactLocalStorage.set(tutorEmailLS, payload.tutorEmail);
      reactLocalStorage.set(tutorNameLS, payload.tutorName);
      reactLocalStorage.set(tutorUserIDLS, payload.tutorUserID);

      return {
        ...state,
        tutorEmail: payload.tutorEmail,
        tutorName: payload.tutorName,
        tutorUserID: payload.tutorUserID,
      };

    case REMOVE_TUTOR_DETAILS:
      reactLocalStorage.remove(tutorEmailLS);
      reactLocalStorage.remove(tutorNameLS);
      reactLocalStorage.remove(tutorUserIDLS);
      return {
        ...state,
        tutorEmail: null,
        tutorName: null,
        tutorUserID: null,
      };
    default:
      return state;
  }
};
