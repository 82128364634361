/* eslint-disable react/prop-types */
import React, { Fragment, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { loadAuthDetails } from "../../actions/authActions";
import { useDispatch } from "react-redux";
import Loading from "../../components/Loading/Loading";

const LandingInfo = ({ match }) => {
  let history = useHistory();
  let token = match.params.landingInfo;
  const dispatch = useDispatch();

  useEffect(() => {
    if (token) {
      console.log(token);
      dispatch(loadAuthDetails(token));
    } else {
      history.push("/login");
    }
  }, [dispatch, history, token]);
  return <Loading />;
};

export default LandingInfo;
