import { applyMiddleware, combineReducers, createStore } from "redux";
import {
  shortlistAPACReducer,
  shortlistCAReducer,
  shortlistReducer,
  shortlistUKReducer,
  shortlistEUReducer,
  shortlistIntegratedMedReducer,
} from "./reducers/shortlistingReducers";

import { alertReducer } from "./reducers/alertReducers";
import { authReducer } from "./reducers/authReducers";
import { composeWithDevTools } from "redux-devtools-extension";
import { studentInfoReducer } from "./reducers/studentInfoReducers";
import { tutorInfoReducer } from "./reducers/tutorReducers";

import {
  getExtracurricularReducer,
  addExtracurricularReducer,
  updateExtracurricularReducer,
} from "./reducers/extracurricularReducers";
import {
  getCurrentMeetingsReducer,
  getPreviousMeetingsReducer,
  addMeetingsReducer,
  deleteMeetingReducer,
} from "./reducers/meetingReducers";

import thunk from "redux-thunk";

const reducer = combineReducers({
  auth: authReducer,
  alert: alertReducer,
  shortlist: shortlistReducer,
  shortlistUK: shortlistUKReducer,
  shortlistCA: shortlistCAReducer,
  shortlistAPAC: shortlistAPACReducer,
  shortlistEU: shortlistEUReducer,
  shortlistIntegratedMed: shortlistIntegratedMedReducer,
  studentInfo: studentInfoReducer,
  getExtracurricular: getExtracurricularReducer,
  addExtracurricular: addExtracurricularReducer,
  updateExtracurricular: updateExtracurricularReducer,
  getCurrentMeetings: getCurrentMeetingsReducer,
  getPreviousMeetings: getPreviousMeetingsReducer,
  addMeetings: addMeetingsReducer,
  deleteMeeting: deleteMeetingReducer,
  tutorInfo: tutorInfoReducer,  // added for tutors
});

const middleware = [thunk];

const store = createStore(
  reducer,
  composeWithDevTools(applyMiddleware(...middleware))
);

export default store;
