export const token = "CollegePassToken_AP";
export const email = "CollegePassEmail_AP";
export const isTestTaken = "CollegePassIsTestTaken_TP";
export const testDetails = "CollegePassTestDetails_TP";
export const discussionBoardIDLS = "CollegePass_Discussion_BoardID_AP";
export const citizenshipIDLS = "CollegePass_Citizenship_AP";

export const shortlistedColleges = "CollegePass_shortlistedColleges_AP";
export const shortlistedCollegesUK = "CollegePass_shortlistedColleges_UK_AP";
export const shortlistedCollegesCA = "CollegePass_shortlistedColleges_CA_AP";
export const shortlistedCollegesAPAC = "CollegePass_shortlistedColleges_APAC";
export const shortlistedCollegesEU = "CollegePass_shortlistedColleges_EU";
export const shortlistedCollegesIntegratedMed =
  "CollegePass_shortlistedColleges_IntegratedMed";
export const clientID = "CollegePass_clientID";
export const refreshToken = "CollegePass_RefreshToken";
export const isAdmin = "CollegePass_IsAdmin";

export const studentEmailLS = "CollegePass_AP_StudentEmail";
export const studentNameLS = "CollegePass_AP_StudentName";
export const studentUserIDLS = "CollegePass_AP_StudentUserID";
export const studentDBIDLS = "CollegePass_AP_StudentDBID";
export const studentCitizenshipIDLS = "CollegePass_AP_StudentCitizenship";

export const tutorEmailLS = "CollegePass_Tutoring_TutorEmail";
export const tutorNameLS = "CollegePass_Tutoring_TutorName";
export const tutorUserIDLS = "CollegePass_Tutoring_TutorID";

export const videoPlayed = "videoPlayed";

export const messageDraft = "CollegePass_AP_MessageDraft";
