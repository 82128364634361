import {
  LOAD_STUDENT_DETAILS,
  REMOVE_STUDENT_DETAILS,
} from "../constants/studentInfoConstants";

import { reactLocalStorage } from "reactjs-localstorage";
import {
  studentEmailLS,
  studentNameLS,
  studentUserIDLS,
  // studentDBIDLS,
  // studentCitizenshipIDLS,
} from "../config/localStorage";

const initialState = {
  studentEmail: reactLocalStorage.get(studentEmailLS, null, true),
  studentName: reactLocalStorage.get(studentNameLS, null, true),
  studentUserID: reactLocalStorage.get(studentUserIDLS, null, true),
  // studentDBID: reactLocalStorage.get(studentDBIDLS, null, true),
  // citizenship: reactLocalStorage.get(studentCitizenshipIDLS, null, true),
};

export const studentInfoReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case LOAD_STUDENT_DETAILS:
      reactLocalStorage.set(studentEmailLS, payload.studentEmail);
      reactLocalStorage.set(studentNameLS, payload.studentName);
      reactLocalStorage.set(studentUserIDLS, payload.studentUserID);
      // reactLocalStorage.set(studentDBIDLS, payload.studentDBID);
      // reactLocalStorage.set(studentCitizenshipIDLS, payload.citizenship);
      return {
        ...state,
        studentEmail: payload.studentEmail,
        studentName: payload.studentName,
        studentUserID: payload.studentUserID,
        // studentDBID: payload.studentDBID,
        // citizenship: payload.citizenship,
      };

    case REMOVE_STUDENT_DETAILS:
      reactLocalStorage.remove(studentEmailLS);
      reactLocalStorage.remove(studentNameLS);
      reactLocalStorage.remove(studentUserIDLS);
      // reactLocalStorage.remove(studentDBIDLS);
      // reactLocalStorage.remove(studentCitizenshipIDLS);
      return {
        ...state,
        studentEmail: null,
        studentName: null,
        studentUserID: null,
        // studentDBID: null,
        // citizenship: null,
      };
    default:
      return state;
  }
};
